import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { MutationActivityResetArgs, Responsible } from "@/graphql/types";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { reactive } from "vue";
import { useSecurityCounter } from "@/graphql/activity/secutrity-counter";

type Data = {
  activityReset: Responsible;
};

const ACTIVITY_RESET = gql`
  mutation ActivityReset($input: ActivityResetInput!) {
    activityReset(input: $input)
  }
`;

export const useActivityReset = () => {
  const toast = useToast();
  const { t } = useI18n();
  const { loading, mutate, onDone } = useMutation<
    Data,
    MutationActivityResetArgs
  >(ACTIVITY_RESET);
  const input = reactive({
    id: 0,
    options: [0, 1, 2, 3],
  });

  const { counter, dialog, resetActivity, launchCounter } =
    useSecurityCounter();

  onDone(({ data }) => {
    if (data?.activityReset) {
      const life = Number(parseInt(process.env.VUE_APP_TOAST_LIFE));
      toast.add({
        severity: "success",
        summary: t("activity.reset.title"),
        detail: t("activity.reset.success"),
        life,
      });
      setTimeout(() => window.location.reload(), life);
    } else {
      toast.add({
        severity: "warn",
        summary: t("activity.reset.title"),
        detail: t("unknownError"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
    dialog.show = false;
  });

  function preventReset(id: number) {
    input.id = id;
    dialog.show = true;
    launchCounter();
  }

  function submitReset() {
    dialog.show = false;
    resetActivity(() => void mutate({ input }));
  }

  return { input, preventReset, dialog, counter, loading, submitReset };
};
