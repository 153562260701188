import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-my-2" }
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InlineMessage = _resolveComponent("InlineMessage")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_Button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.preventReset(_ctx.id))),
      class: "p-button-warning",
      "icon-pos": "right",
      icon: "pi pi-refresh"
    }, null, 512), [
      [_directive_tooltip, _ctx.$t('reset')]
    ]),
    _createVNode(_component_Dialog, {
      header: _ctx.$t('activity.reset.title'),
      visible: _ctx.dialog.show,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialog.show) = $event)),
      breakpoints: { '960px': '80vw' },
      style: { width: '40vw' },
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('cancel'),
          icon: "pi pi-times",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dialog.show = false)),
          class: "p-button-text",
          autofocus: ""
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          loading: _ctx.loading,
          disabled: _ctx.loading || _ctx.counter !== 0,
          onClick: _ctx.submitReset,
          icon: "pi pi pi-exclamation-triangle",
          class: "p-button-raised p-button-warning",
          "icon-pos": "right",
          label: _ctx.counter !== 0 ? `${_ctx.counter}` : _ctx.$t('confirm')
        }, null, 8, ["loading", "disabled", "onClick", "label"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_InlineMessage, { severity: "warn" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("activity.reset.warning")), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("activity.reset.also")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('activity.reset.options'), (opt, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "field-checkbox p-mb-2"
          }, [
            _createVNode(_component_Checkbox, {
              id: `reset-options-${index}`,
              value: index,
              name: "options",
              modelValue: _ctx.input.options,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.input.options) = $event)),
              class: "p-mr-2"
            }, null, 8, ["id", "value", "modelValue"]),
            _createElementVNode("label", {
              for: `reset-options-${index}`
            }, _toDisplayString(opt), 9, _hoisted_2)
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["header", "visible"])
  ], 64))
}