
import { defineComponent } from "vue";
import { useActivityReset } from "@/graphql/activity/activity.reset";

export default defineComponent({
  name: "ActivityReset",
  props: {
    id: Number,
  },
  setup() {
    return {
      ...useActivityReset(),
    };
  },
});
